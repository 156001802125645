<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Empresas"
        p-read-link="userType.read.one"
        p-create-link="userType.create"
        p-name="usersTypes"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwUserTypeRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'Tipo',
                value: 'descricao'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>